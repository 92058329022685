import { default as dashboard5ON3tGEPwOMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/admin/dashboard.vue?macro=true";
import { default as loginFXYLEywLViMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/admin/login.vue?macro=true";
import { default as choose_45categoryoGppHIonMIMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/choose-category.vue?macro=true";
import { default as defaultJtDUdx7KpOMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/default.vue?macro=true";
import { default as indexCfd06Xax9NMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/index.vue?macro=true";
import { default as claim_45prizeaBwKf84WtUMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/claim-prize.vue?macro=true";
import { default as indexg7ciFieUBpMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/index.vue?macro=true";
import { default as max_45playedrQy2UOy5dvMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/max-played.vue?macro=true";
import { default as no_45winG6Xq1HDOYlMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/no-win.vue?macro=true";
import { default as prize_45claimedMNsBmbcJ3eMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/prize-claimed.vue?macro=true";
import { default as winDdG468QarnMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/win.vue?macro=true";
import { default as wrong_45answer9mEU2D2uvzMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/wrong-answer.vue?macro=true";
import { default as answer_45correctzULb0uN4yuMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/answer-correct.vue?macro=true";
import { default as begFLnrlDeE0Meta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/be.vue?macro=true";
import { default as congratulations7MNGJA8hWeMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/congratulations.vue?macro=true";
import { default as form3zn3uJgXuXMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/form.vue?macro=true";
import { default as loginKR5hKw5579Meta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/login.vue?macro=true";
import { default as questionwILW3fODyzMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/question.vue?macro=true";
import { default as thanksPhnm4Yy1LFMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/thanks.vue?macro=true";
import { default as wheel8W8IgIMxVkMeta } from "/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/wheel.vue?macro=true";
import { default as choose_45categoryjN3onVU1y5Meta } from "~/pages/choose-category.vue?macro=true";
import { default as wrong_45answer9GjULBXgXmMeta } from "~/pages/quiz/wrong-answer.vue?macro=true";
export default [
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboard5ON3tGEPwOMeta || {},
    alias: ["/dashboard"],
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/admin/dashboard.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: loginFXYLEywLViMeta || {},
    alias: ["/login"],
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/admin/login.vue")
  },
  {
    name: "choose-category",
    path: "/choose-category",
    meta: choose_45categoryoGppHIonMIMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/choose-category.vue")
  },
  {
    name: "default",
    path: "/default",
    meta: defaultJtDUdx7KpOMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/default.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexCfd06Xax9NMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/index.vue")
  },
  {
    name: "quiz-claim-prize",
    path: "/quiz/claim-prize",
    meta: claim_45prizeaBwKf84WtUMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/claim-prize.vue")
  },
  {
    name: "quiz",
    path: "/quiz",
    meta: indexg7ciFieUBpMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/index.vue")
  },
  {
    name: "quiz-max-played",
    path: "/quiz/max-played",
    meta: max_45playedrQy2UOy5dvMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/max-played.vue")
  },
  {
    name: "quiz-no-win",
    path: "/quiz/no-win",
    meta: no_45winG6Xq1HDOYlMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/no-win.vue")
  },
  {
    name: "quiz-prize-claimed",
    path: "/quiz/prize-claimed",
    meta: prize_45claimedMNsBmbcJ3eMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/prize-claimed.vue")
  },
  {
    name: "quiz-win",
    path: "/quiz/win",
    meta: winDdG468QarnMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/win.vue")
  },
  {
    name: "quiz-wrong-answer",
    path: "/quiz/wrong-answer",
    meta: wrong_45answer9mEU2D2uvzMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/quiz/wrong-answer.vue")
  },
  {
    name: "static-answer-correct",
    path: "/static/answer-correct",
    meta: answer_45correctzULb0uN4yuMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/answer-correct.vue")
  },
  {
    name: "static-be",
    path: "/static/be",
    meta: begFLnrlDeE0Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/be.vue")
  },
  {
    name: "static-congratulations",
    path: "/static/congratulations",
    meta: congratulations7MNGJA8hWeMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/congratulations.vue")
  },
  {
    name: "static-form",
    path: "/static/form",
    meta: form3zn3uJgXuXMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/form.vue")
  },
  {
    name: "static-login",
    path: "/static/login",
    meta: loginKR5hKw5579Meta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/login.vue")
  },
  {
    name: "static-question",
    path: "/static/question",
    meta: questionwILW3fODyzMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/question.vue")
  },
  {
    name: "static-thanks",
    path: "/static/thanks",
    meta: thanksPhnm4Yy1LFMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/thanks.vue")
  },
  {
    name: "static-wheel",
    path: "/static/wheel",
    meta: wheel8W8IgIMxVkMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20250125071819/pages/static/wheel.vue")
  },
  {
    name: "choose-category-nl",
    path: "/kies-categorie",
    meta: choose_45categoryjN3onVU1y5Meta || {},
    component: () => import("~/pages/choose-category.vue")
  },
  {
    name: "wrong-answer-nl",
    path: "/quiz/fout-antwoord",
    meta: wrong_45answer9GjULBXgXmMeta || {},
    component: () => import("~/pages/quiz/wrong-answer.vue")
  }
]